import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Strong, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdFreeBreakfast, MdStarBorder } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Angebote | GrandSlam Courts
			</title>
			<meta name={"description"} content={"Jeden Aufschlag verbessern, jedes Spiel perfektionieren"} />
			<meta property={"og:title"} content={"Angebote | GrandSlam Courts"} />
			<meta property={"og:description"} content={"Jeden Aufschlag verbessern, jedes Spiel perfektionieren"} />
			<link rel={"shortcut icon"} href={"https://zenitorfax.com/img/3176367.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://zenitorfax.com/img/9.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 50px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline2" margin="0 0 12px 0">
				Entdecken Sie unsere erstklassigen Tennisservices
				</Text>
				<Text font="--base">
				Bei GrandSlam Courts bieten wir mehr als nur Plätze – wir schaffen eine Umgebung, die jeden Aspekt Ihres Tennisspiels fördert. Von hochmodernen Einrichtungen bis hin zu persönlichem Coaching sind unsere Services auf Spieler aller Niveaus ausgerichtet und zielen darauf ab, ihr Spiel in einem umfassenden, unterstützenden Umfeld zu verbessern. Erleben Sie den Unterschied mit unseren erstklassigen Tennisangeboten.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--indigo"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Platzreservierungen
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Erweitertes Buchungssystem: Reservieren Sie ganz einfach im Voraus einen Platz über unser optimiertes Buchungssystem, damit Sie immer einen Platz zum Spielen haben.
						<br />
						<br />
						Vielfalt der Oberflächen: Wählen Sie zwischen Sand-, Hart- oder Rasenplätzen, die alle nach professionellen Standards für optimales Spiel gepflegt werden.
						<br />
						<br />
						Beleuchtung für Nachtspiele: Unsere Plätze sind mit hochwertiger Beleuchtung ausgestattet, sodass Abend- oder Nachtsitzungen genauso unterhaltsam sind wie das Spielen tagsüber.

					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-red"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--red"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Coaching und Kurse
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Personalisiertes Coaching: Maßgeschneiderte Sitzungen von zertifizierten Tennistrainern, die auf Ihren Stil und Ihre Ziele eingehen.
						<br/><br/>
						Gruppenkurse: Treffen Sie Gleichaltrige mit ähnlichem Können in Gruppenkursen, die darauf ausgelegt sind, bestimmte Aspekte Ihres Spiels zu verbessern.
						<br/><br/>
						Juniorenprogramme: Spezialprogramme für junge Spieler mit Schwerpunkt auf Grundlagen, Sportlichkeit und Wettkampfspiel.


					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-green"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--green"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Ausrüstung und Zubehör
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Pro Shop: Ausgestattet mit der neuesten Tennisausrüstung, von Schlägern und Schuhen bis hin zu Bekleidung und Zubehör.
						<br/><br/>
						Besaitungsservice für Schläger: Fachmännische Besaitungsservices, um Ihren Schläger in Topform zu halten.
						<br/><br/>
						Demoschläger: Probieren Sie mit unserem Demoschlägerprogramm Schläger vor dem Kauf aus und finden Sie so den Schläger, der perfekt zu Ihrem Spielstil passt.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-orange"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdStarBorder}
							color="--orange"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Ausstattung für Spieler
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Umkleideräume: Geräumige und sichere Schließfächer zur bequemen Aufbewahrung Ihrer Ausrüstung.
						<br/><br/>
						Spielerlounge: Entspannen Sie sich vor oder nach Spielen in unserem Loungebereich, der für Spieler zum Kontakteknüpfen und Entspannen konzipiert ist.
						<br/><br/>
						Fitnessbereich: Zugang zu einem speziellen Fitnessbereich, der dabei hilft, die körperliche Verfassung speziell für Tennis zu verbessern.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://zenitorfax.com/img/10.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://zenitorfax.com/img/11.jpg"
					/>
				</Box>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
					margin="10px 0px 0px 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://zenitorfax.com/img/12.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
					margin="10px 0px 100px 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://zenitorfax.com/img/13.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fbf0fdbe26f0020fd0d1b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});